/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@include mat.core();

$primary: mat.define-palette(mat.$grey-palette, 900);
$accent: mat.define-palette(mat.$grey-palette, 900);

$theme: mat.define-light-theme($primary, $accent);

@include mat.all-component-themes($theme);
