/* You can add global styles to this file, and also import other style files */
@import "@angular/material/prebuilt-themes/deeppurple-amber.css";
@import url("https://fonts.googleapis.com/css?family=Roboto");

.btnAction,
.btnActionWait {
  margin-left: 5vw;
  margin-right: 5vw;
  width: 90vw;
  background: #80e150;
  font-weight: bold;
  color: white;
  font-size: 4.45vw;
  border-radius: 35px;
  font-family: "Roboto", sans-serif;
  margin-top: 2vh;
  margin-bottom: 2vh;
  height: 6vh;
}

.btnAction.disabled {
  background-color: grey;
}

.footerCustom {
  position: absolute;
  bottom: 0px;
  width: 100vw;
  background: #f7f7f7;
  min-height: 6vh;
  height: 10.5vh;
}
